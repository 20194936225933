import React, { Component, useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu";
import { FetchSubScriberAction, FetchUserFeedback, } from '../../redux/actions/data.action'
import { FetchUsers } from '../../redux/actions/auth.action'

import { connect } from 'react-redux'
import Modal from "../modals";
import AdminNavBar from "../../components/AdminNavBar";
function Subscribers(props) {


    const [array, setArray] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [userObj, setUserObject] = useState({

    })



    useEffect(() => {
        props.FetchSubScriberAction()
        props.FetchUsers()
        props.FetchUserFeedback("Aussie-mint Gold Refinery")
        const user = JSON.parse(localStorage.getItem("userInfo"));
        setUserObject(JSON.parse(localStorage.getItem("userInfo")))
        if (!user) {
            window.location.href = "/admin/login"
        }


    }, []);

    return (
        <div>
            {/* Navigation bar */}
            <AdminNavBar user={userObj} />

            <div className="page-wrapper section-space--inner--120">
                {/*Contact section start*/}
                <div className="conact-section">
                    <div className="container">
                        <div className="row section-space--bottom--50">
                            <div className="col" style={{ display: "flex", flexWrap: 'wrap', gap: '10px', }}>
                                <div className="col-4" style={styles.cardContainer}>
                                    <div style={styles.contentContainer}>
                                        <div style={styles.iconContainer}>

                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                style={{ height: '30px' }}
                                                fill="black" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-2 h-2 text-black">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                                            </svg>

                                        </div>
                                        <div style={styles.titleContainer}>
                                            SubScribers
                                        </div>
                                        <div style={styles.valueContainer}>
                                            {props.subscribers.length}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4" style={styles.cardContainer}>
                                    <div style={styles.contentContainer}>
                                        <div style={styles.iconContainer}>

                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                style={{ height: '30px' }}
                                                fill="black" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-2 h-2 text-black">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                            </svg>

                                        </div>
                                        <div style={styles.titleContainer}>
                                            Users

                                        </div>
                                        <div style={styles.valueContainer}>
                                            {props.users.length}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4" style={styles.cardContainer}>
                                    <div style={styles.contentContainer}>
                                        <div style={styles.iconContainer}>
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ height: '30px' }}
                                                fill="black" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-2 h-2 text-black">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
                                            </svg>


                                        </div>
                                        <div style={styles.titleContainer}>

                                            Users FeedBacks
                                        </div>
                                        <div style={styles.valueContainer}>
                                            {props.feedBacks.length}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Mobile Menu */}
            <MobileMenu />
        </div>
    );
}


const mapStateToProps = (state) => {
    return {

        loading: state.priceDetails.loading,
        feedBacks: state.priceDetails.feedBacks,

        subscribers: state.priceDetails.subscribers,
        users: state.userDetails.users,

    };
};


const styles = {
    cardContainer: { display: 'flex', height: '200px', border: 'dotted gray 1px', width: '24%', borderRadius: '10px', alignItems: 'center', justifyContent: 'center', },
    contentContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' },
    titleContainer: { text: "white", fontWeight: 'bold', textTransform: 'uppercase', fontSize: '26px' },
    valueContainer: { text: "white", fontWeight: 'bold', textTransform: 'uppercase', },
    iconContainer: { backgroundColor: '#d2ac67',border:'red,dotted 1px', borderRadius: '50px', padding: '20px', height: '70px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }
}
export default connect(mapStateToProps, { FetchSubScriberAction, FetchUserFeedback, FetchUsers })(Subscribers)

