import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? document.getElementById("scroll-top").classList.add("show")
      : document.getElementById("scroll-top").classList.remove("show");
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  footer area ====================*/}
        <div className="footer-area dark-bg"
          style={{ paddingTop: '50px', paddingBottom: '10px' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-content-wrapper ">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-12">
                      {/* footer intro wrapper */}
                      <div className="footer-intro-wrapper">
                        <div className="footer-logo">
                          <a href={`${process.env.PUBLIC_URL}/home-one`}>
                            <img
                              src="assets/assets/aussie-logo.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </div>
                        {/* <div className="footer-desc">
                          Lorem ipsum dolor sit amet consect adipisici elit, sed
                          do eiusmod tempor incididuk ut labore et dolore magna
                          aliqua Ut enim
                        </div> */}
                      </div>
                    </div>
                    <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget">
                        <h4 className="footer-widget__title"  style={{ marginBottom: "10px" }}>
                          NAVIGATION</h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>Home</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/assay`}>
                              Assay
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/about_us`}>
                              About Us
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/refinaring`}>Refinery</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget">
                        <h4 className="footer-widget__title" style={{ marginBottom: "10px" }}>GET IN TOUCH</h4>
                        <h4 style={{ color: "white", textAlign: 'left' }}>You can get an Assay report with Us </h4>
                        <div className="border-gold full" >

                          <div className="button center" style={{ width: '100%' }}>
                            <a
                              href={`${process.env.PUBLIC_URL}/contact-us`}
                              className="see-more-link"
                            >Contact Us</a> </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget mb-0">
                        <h4 className="footer-widget__title" style={{ marginBottom: "10px" }}>CONTACT US</h4>
                        <div className="footer-widget__content">
                          <p className="address">
                            2nd Parklands Avenue,
                          </p>
                          <ul className="contact-details">
                            <li>
                              <span>P:</span>+254 788 283 594
                            </li>
                            <li>
                              <span>F:</span>55 44 77 62
                            </li>
                            <li>
                              <span>E:</span>info@aussiemint.com
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright-wrapper">
            <div className="footer-copyright text-center">
              <p className="copyright-text">
                &copy; Aussiemint {new Date().getFullYear()}
              </p>
            </div>
          </div>
        </div>
        {/*====================  End of footer area  ====================*/}
        {/*====================  scroll top ====================*/}
        <button
          className="scroll-top center"
          id="scroll-top"
          onClick={this.scrollToTop}
          style={{ backgroundColor: "transparent" }}
        >
          {/* <i className="ion-android-arrow-up" /> */}
          <a href="https://api.whatsapp.com/send?phone=0788283594" target='_blank'><img src="assets/assets/whatsap.png" height="100%" width="100%" alt="" />
          </a> </button>

        {/*====================  End of scroll top  ====================*/}
      </div >
    );
  }
}

export default Footer;
